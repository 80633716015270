
import { CameraStatus } from "@evercam/shared/types/camera"
export default {
  name: "CameraStatusIcon",
  props: {
    camera: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    cameraStatuses() {
      return {
        [CameraStatus.OFFLINE]: {
          icon: this.$device.isIos
            ? "fas fa-plug-circle-xmark"
            : "fa fa-unlink",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.OFFLINE_SCHEDULED]: {
          icon: this.$device.isIos
            ? "fas fa-plug-circle-xmark"
            : "fa fa-unlink",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.WAITING]: {
          icon: "far fa-clock",
          color: "",
        },
        [CameraStatus.UNDER_MAINTENANCE]: {
          icon: "fa fa-wrench",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.WAITING_FOR_SITE_VISIT]: {
          icon: "fa fa-wrench",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.DECOMMISSIONED]: {
          icon: this.$device.isIos
            ? "fas fa-circle-check"
            : "fas fa-clipboard-check",
          color: "",
        },
        [CameraStatus.ON_HOLD]: {
          icon: "far fa-pause-circle",
          color: "",
        },
      }
    },
    isCameraOnline() {
      return this.camera?.status === CameraStatus.ONLINE
    },
    cameraStatus() {
      return this.cameraStatuses?.[this.camera?.status]
    },
    cameraStatusColor() {
      return this.cameraStatus?.color
    },
    cameraStatusIcon() {
      return this.cameraStatus?.icon
    },
  },
}
