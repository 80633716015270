
export default {
  name: "WeatherIcon",
  props: {
    item: {
      type: Object,
      default: () => ({ weather: [] }),
    },
    size: {
      type: Number,
      default: 64,
    },
  },
  computed: {
    src() {
      return `http://${this.$config.public.openWeatherApi}/img/wn/${this.item.weather[0]?.icon}@2x.png`
    },
  },
}
