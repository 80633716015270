import { ImageQuality } from "@evercam/shared/types"
import { base64UrlEncode } from "@evercam/shared/utils"
import axios from "@evercam/shared/api/client/axios"

function getResizedImageUrl(url, quality, suffix = true) {
  const filePath = base64UrlEncode(url)

  return `${
    axios.env.snapshotsURL
  }/sig/size:${quality}:99999/resizing_type:fit/${filePath}${
    suffix ? ".jpeg" : ""
  }`
}

function get360pResizedImageUrl(url, suffix = true) {
  return getResizedImageUrl(url, ImageQuality._360, suffix)
}

function get720pResizedImageUrl(url, suffix = true) {
  return getResizedImageUrl(url, ImageQuality._720, suffix)
}

function get1080pResizedImageUrl(url, suffix = true) {
  return getResizedImageUrl(url, ImageQuality._1080, suffix)
}

export default (app) => {
  const imgproxy = {
    get360pResizedImageUrl,
    get720pResizedImageUrl,
    get1080pResizedImageUrl,
    getResizedImageUrl,
  }

  app.provide("imgproxy", imgproxy)
}
