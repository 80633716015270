import { GateReportVehicleType } from "@evercam/shared/types"

export const VEHICLE_TYPE_IDS = Object.values(GateReportVehicleType)

export const VEHICLE_TYPES = [
  { name: "Tipping truck", id: GateReportVehicleType.tippingTruck },
  { name: "Concrete truck", id: GateReportVehicleType.truckMixer },
  { name: "Tank truck", id: GateReportVehicleType.tankTruck },
  { name: "Semi trailer", id: GateReportVehicleType.semiTrailer },
  { name: "Truck head", id: GateReportVehicleType.truckHead },
  {
    name: "Flatbed semi-trailer",
    id: GateReportVehicleType.flatbedSemiTrailer,
  },
  { name: "Small truck", id: GateReportVehicleType.smallTruck },
  { name: "Road vehicle", id: GateReportVehicleType.roadVehicle },
  { name: "Concrete pump", id: GateReportVehicleType.concretePump },
  { name: "Other vehicles", id: GateReportVehicleType.otherTruck },
  { name: "Unknown", id: GateReportVehicleType.unknown },
]
