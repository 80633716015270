
import Highcharts from "highcharts"
import WindbarbHighcharts from "highcharts/modules/windbarb"
import VueHighcharts from "@evercam/shared/components/Highcharts"
import { AnalyticsEvent, UnitSystem } from "@evercam/shared/types"

export default {
  components: {
    highcharts: VueHighcharts,
  },
  props: {
    timezone: {
      type: String,
      default: "Europe/Dublin",
    },
    isWeatherHistory: {
      type: Boolean,
      default: false,
    },
    weather: {
      type: [Object, Array, null],
      default: null,
    },
    unitSystem: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      temperatures: [],
      precipitations: [],
      winds: [],
      pressures: [],
      weatherIcons: [],
      weatherChartOptions: {},
      weatherImages: [],
      humidities: [],
    }
  },
  computed: {
    temperatureUnit() {
      return this.unitSystem === UnitSystem.Imperial ? "F" : "C"
    },
    windSpeedUnit() {
      return this.$t("content.units." + this.unitSystem + ".speed")
    },
    rainVolumeUnit() {
      return this.$t("content.units." + this.unitSystem + ".rain")
    },
  },
  watch: {
    weather: {
      immediate: true,
      deep: true,
      handler() {
        this.parseWeatherData()
      },
    },
    unitSystem() {
      this.parseWeatherData()
    },
  },
  created() {
    if (typeof Highcharts === "object") {
      WindbarbHighcharts(Highcharts)
    }
    this.$addEventListener("orientationchange", this.onResize)
  },
  methods: {
    onResize() {
      this.$setTimeout(this.parseWeatherData, 300)
    },
    mouseOver(chart) {
      const hoverIndex = chart.target.index
      let hoveredWeather = {}

      hoveredWeather.wind = this.winds[hoverIndex].value || 0
      hoveredWeather.temperature = this.temperatures[hoverIndex].y || 0
      hoveredWeather.precipitation = this.precipitations[hoverIndex].y || 0
      hoveredWeather.humidity = this.humidities[hoverIndex]

      this.$emit("hoverChart", hoveredWeather)
    },
    mouseOut() {
      this.$emit("hoverChart", {
        wind: null,
        precipitation: null,
        temperature: null,
      })
    },
    reset() {
      this.temperatures = []
      this.precipitations = []
      this.winds = []
      this.pressures = []
      this.weatherIcons = []
      this.weatherChartOptions = {}
      this.humidities = []
    },
    getWeatherChartOptions() {
      return {
        title: {
          text: "",
        },
        chart: {
          scrollablePlotArea: {
            scrollPositionX: 0,
            outside: false,
          },
          styledMode: true,
        },
        tooltip: {
          shared: true,
          useHTML: true,
          outside: false,
          headerFormat:
            "<div class='on_background--text'><small>{point.x:%A, %b %e, %H:%M} - {point.point.to:%H:%M}</small><br>" +
            "<b>{point.point.symbolName}</b><br></div>",
        },
        xAxis: [
          {
            // Bottom X axis
            type: "datetime",
            tickInterval: 2 * 36e5, // two hours
            minorTickInterval: 36e5, // one hour
            tickLength: 0,
            gridLineWidth: 1,
            startOnTick: false,
            endOnTick: false,
            minPadding: 0,
            maxPadding: 0,
            offset: 30,
            showLastLabel: true,
            labels: {
              format: "{value:%H}",
            },
            crosshair: true,
          },
          {
            // Top X axis
            linkedTo: 0,
            type: "datetime",
            tickInterval: 12 * 3600 * 1000,
            labels: {
              format:
                '{value:<span style="font-size: 12px; font-weight: bold">%a</span> %e %b %H:%M}',
              align: "left",
              x: 3,
              y: -5,
            },
            opposite: true,
            tickLength: 20,
            gridLineWidth: 1,
          },
        ],

        yAxis: [
          {
            // temperature axis
            title: {
              text: this.temperatureUnit,
            },
            labels: {
              format: "{value}°",
              style: {
                fontSize: "10px",
              },
              x: -3,
              step: 1,
            },
            plotLines: [
              {
                // zero plane
                value: 0,
                width: 1,
                zIndex: 2,
              },
            ],
            maxPadding: 0.3,
            minRange: 8,
            tickInterval: this.unitSystem === UnitSystem.Imperial ? 10 : 2.5,
          },
          {
            // precipitation axis
            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
            gridLineWidth: 0,
            tickLength: 0,
            minRange:
              this.unitSystem === UnitSystem.Imperial && this.isWeatherHistory
                ? 1
                : 10,
            min: 0,
          },
          {
            // Air pressure
            allowDecimals: false,
            title: {
              // Title on top of axis
              text: "hPa",
              offset: 0,
              align: "high",
              rotation: 0,
              style: {
                fontSize: "10px",
              },
              textAlign: "left",
              x: 3,
            },
            labels: {
              style: {
                fontSize: "8px",
              },
              y: 2,
              x: 3,
              step: 1,
            },
            gridLineWidth: 0,
            opposite: true,
            showLastLabel: false,
            tickInterval: 5,
          },
        ],
        plotOptions: {
          series: {
            pointPlacement: "between",
            point: {
              events: {
                mouseOver: this.mouseOver,
                mouseOut: this.mouseOut,
              },
            },
          },
        },

        series: [
          {
            name: "Temperature",
            data: this.temperatures,
            type: "spline",
            marker: {
              enabled: false,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
            tooltip: {
              pointFormat:
                '<div class="on_background--text"><span>\u25CF</span> ' +
                `{series.name}: <b>{point.y}°${this.temperatureUnit}</b></div>`,
            },
            zIndex: 1,
          },
          {
            name: "Rain",
            data: this.precipitations,
            type: "column",
            yAxis: 1,
            groupPadding: 0,
            pointPadding: 0,
            grouping: false,
            dataLabels: {
              filter: {
                operator: ">",
                property: "y",
                value: 0,
              },
              style: {
                fontSize: "8px",
                color: "gray",
              },
            },
            tooltip: {
              pointFormat:
                '<div class="on_background--text"><span>\u25CF</span> ' +
                `{series.name}: <b>{point.y} ${this.rainVolumeUnit}</b></div>`,
            },
          },
          {
            name: "Air pressure",
            data: this.pressures,
            marker: {
              enabled: false,
            },
            shadow: false,
            tooltip: {
              pointFormat:
                '<div class="on_background--text"><span>\u25CF</span> ' +
                "{series.name}: <b>{point.y} hPa</b></div>",
            },
            dashStyle: "shortdot",
            yAxis: 2,
          },
          {
            name: "Wind",
            type: "windbarb",
            id: "windbarbs",
            lineWidth: 1.5,
            data: this.winds,
            vectorLength: 18,
            yOffset: -15,
            xOffset: -6,
            tooltip: {
              pointFormat:
                '<div class="on_background--text"><span>\u25CF</span> ' +
                `{series.name}: <b>{point.value} ${this.windSpeedUnit}</b></div>`,
            },
          },
        ],
        legend: {
          events: {
            itemClick: this.clickLegendItem,
          },
        },
      }
    },
    clickLegendItem(data) {
      this.$nextTick(() => {
        if (!data?.legendItem) {
          return
        }
        this.$root.$emit("analytics-event", {
          eventId: AnalyticsEvent.weatherSelectLegend,
          params: {
            type: data.legendItem.name,
            visible: data.legendItem.visible,
          },
        })
      })
    },
    addChartFeatures(chart) {
      this.drawBlocksForWindArrows(chart)

      chart?.get("windbarbs")?.markerGroup.attr({
        translateX: chart?.get("windbarbs")?.markerGroup?.translateX + 8,
      })
      // To hide Highcharts default credit element
      document.getElementsByClassName("highcharts-credits")[0].style.display =
        "none"

      this.drawWeatherSymbols(chart)
    },
    drawWeatherSymbols(chart) {
      if (this.weatherImages.length) {
        for (const image of this.weatherImages) {
          image.destroy()
        }
        this.weatherImages = []
      }
      chart?.series[0]?.data.forEach((point, i) => {
        if (this.weatherIcons[i]) {
          this.weatherImages.push(
            chart.renderer
              .image(
                `http://${this.$config.public.openWeatherApi}/img/wn/${this.weatherIcons[i]}@2x.png`,
                point.plotX + chart.plotLeft - 15,
                point.plotY + chart.plotTop - 30,
                30,
                30
              )
              .attr({
                zIndex: 5,
              })
              .add()
          )
        }
      })
    },
    drawBlocksForWindArrows(chart) {
      const xAxis = chart.xAxis[0]

      for (
        let pos = xAxis.min, max = xAxis.max, i = 0;
        pos <= max + 36e5;
        pos += 36e5, i += 1
      ) {
        // Get the X position
        const isLast = pos === max + 36e5,
          x = Math.round(xAxis.toPixels(pos)) + (isLast ? 0.5 : -0.5)

        // Draw the vertical dividers and ticks
        const isLong = i % 2 === 0

        chart.renderer
          .path([
            "M",
            x,
            chart.plotTop + chart.plotHeight + (isLong ? 0 : 28),
            "L",
            x,
            chart.plotTop + chart.plotHeight + 32,
            "Z",
          ])
          .attr({
            stroke: chart.options.chart.plotBorderColor,
            "stroke-width": 1,
          })
          .add()
      }
    },
    getTemperature(temperature) {
      if (temperature >= 273) {
        return Number.parseInt(
          this.unitSystem === UnitSystem.Imperial
            ? this.$units.kelvinToFahrenheit(temperature)
            : this.$units.kelvinToCelsius(temperature)
        )
      } else {
        return Number.parseInt(
          this.unitSystem === UnitSystem.Imperial
            ? this.$units.celsiusToFahrenheit(temperature)
            : temperature
        )
      }
    },
    parseWeatherData() {
      this.reset()
      let pointStart
      const format = "YYYY-MM-DDTHH:mm:ss"

      this.weather.forEach((node, i) => {
        const x = Date.parse(
          this.$moment.tz(node.hour, this.timezone).format(format) + "+00:00"
        )
        const to = x + 6 * 36e5

        if (to > pointStart + 48 * 36e5) {
          return
        }

        //Temperature
        let temp = node.temp
        if (this.unitSystem === UnitSystem.Imperial) {
          temp = this.$units.celsiusToFahrenheit(temp)
        }
        this.temperatures.push({
          x,
          y: Number(temp?.toFixed(1)),
          to,
          symbolName: node.weather[0]?.main,
        })

        //Weather icons
        this.weatherIcons.push(node.weather[0]?.icon)

        //Precipitation
        let rainVolume
        if (node.rain && node.rain["1h"]) {
          rainVolume = node.rain["1h"]
        }
        if (this.unitSystem === UnitSystem.Imperial && this.isWeatherHistory) {
          rainVolume = this.$units.mmToInches(rainVolume)
        }

        this.precipitations.push({
          x,
          y: Number(rainVolume?.toFixed(3)),
        })

        // Humidity
        this.humidities.push(node.humidity)

        //Wind
        let windSpeed = this.$units.mpsTokmh(node.windSpeed)
        if (this.unitSystem === UnitSystem.Imperial) {
          windSpeed = this.$units.kmhToMilesPerHour(windSpeed)
        }

        this.winds.push({
          x,
          value: Math.round(windSpeed),
          direction: node.windDeg,
        })

        //Pressure
        this.pressures.push({
          x,
          y: node.pressure,
        })

        if (i === 0) {
          pointStart = (x + to) / 2
        }
      })

      this.weatherChartOptions = this.getWeatherChartOptions()
      //Re-render weatherIcons after the chart data has been updated
      if (this.$refs.container?.chart) {
        setTimeout(() => {
          this.drawWeatherSymbols(this.$refs.container?.chart)
        }, 1)
      }
    },
  },
}
