import {
  CameraStatus,
  CameraFeatureFlag,
  ResolutionDimensions,
  ResolutionLabel,
} from "@evercam/shared/types/camera"
import {
  ProjectStatus,
  ProjectFeatureFlag,
} from "@evercam/shared/types/project"
import { Persona, UserFeatureFlag } from "@evercam/shared/types/user"
import { ShareRequestStatus } from "@evercam/shared/types/shares"
import {
  MediaType,
  MediaStatus,
  MediaStatusId,
} from "@evercam/shared/types/media"
import { TimelapseStatus } from "@evercam/shared/types/timelapse"
import { CompareStatus } from "@evercam/shared/types/compare"
import { BimModelType } from "@evercam/shared/types/bim"

export const SHARE_REQUEST_STATUSES = [
  { value: ShareRequestStatus.ALL, text: "All" },
  { value: ShareRequestStatus.PENDING, text: "Pending" },
  { value: ShareRequestStatus.CANCELLED, text: "Cancelled" },
  { value: ShareRequestStatus.FAILED, text: "Failed" },
  { value: ShareRequestStatus.USED, text: "Used" },
]

export const SNAPSHOT_EXTRACTION_STATUS = {
  COMPLETED: "completed",
  FAILED: "failed",
  PROCESSING: "processing",
}

export const SNAPSHOT_EXTRACTION_STATUSES = [
  { name: "Completed", value: SNAPSHOT_EXTRACTION_STATUS.COMPLETED },
  { name: "Failed", value: SNAPSHOT_EXTRACTION_STATUS.FAILED },
  { name: "Processing", value: SNAPSHOT_EXTRACTION_STATUS.PROCESSING },
]

export const SNAPSHOT_EXTRACTION_TYPE = {
  CLOUD: "cloud",
  LOCAL: "local",
  TIMELAPSE: "timelapse",
}

export const SNAPSHOT_EXTRACTION_TYPES = [
  { name: "Cloud", value: SNAPSHOT_EXTRACTION_TYPE.CLOUD },
  { name: "Local", value: SNAPSHOT_EXTRACTION_TYPE.LOCAL },
]

export const NVR_HDD_STATUSES = [
  { name: "Error", value: "error" },
  { name: "Idle", value: "idle" },
  { name: "Not Exist", value: "notexist" },
  { name: "Ok", value: "ok" },
  { name: "Unformatted", value: "unformatted" },
]

export const MediaTypes = [
  {
    name: "Clip",
    value: MediaType.Clip,
  },
  {
    name: "Local Clip",
    value: MediaType.LocalClip,
  },
  {
    name: "Compare",
    value: MediaType.Compare,
  },
  {
    name: "Url",
    value: MediaType.ExternalUrl,
  },
  {
    name: "File",
    value: MediaType.File,
  },
  {
    name: "XRay",
    value: MediaType.XRay,
  },
  {
    name: "Edit",
    value: MediaType.EditedImage,
  },
  {
    name: "Timelapse",
    value: MediaType.Timelapse,
  },
]

export const MediaStatuses = [
  {
    name: "Pending",
    value: MediaStatus.Pending,
    id: MediaStatusId.Pending,
  },
  {
    name: "Processing",
    value: MediaStatus.Processing,
    id: MediaStatusId.Processing,
  },
  {
    name: "Completed",
    value: MediaStatus.Completed,
    id: MediaStatusId.Completed,
  },
  {
    name: "Failed",
    value: MediaStatus.Failed,
    id: MediaStatusId.Failed,
  },
]

export const CompareStatuses = [
  {
    name: "Processing",
    value: CompareStatus.Processing,
  },
  {
    name: "Completed",
    value: CompareStatus.Completed,
  },
  {
    name: "Failed",
    value: CompareStatus.Failed,
  },
]

export const TimelapseStatuses = [
  {
    name: "Pending",
    value: TimelapseStatus.Pending,
  },
  {
    name: "Completed",
    value: TimelapseStatus.Completed,
  },
  {
    name: "Failed",
    value: TimelapseStatus.Failed,
  },
]

export const CAMERA_STATUSES_IDS_CLASSES = {
  [CameraStatus.ONLINE]: {
    class: "green--text text--darken-2",
    value: "Online",
  },
  [CameraStatus.OFFLINE]: {
    class: "red--text text--darken-2",
    value: "Offline",
  },
  [CameraStatus.OFFLINE_SCHEDULED]: {
    class: "red--text text--darken-2",
    value: "Offline (Scheduled)",
  },
  [CameraStatus.DECOMMISSIONED]: {
    class: "cyan--text text--darken-2",
    value: "Decommissioned",
  },
  [CameraStatus.ON_HOLD]: {
    class: "deep-orange--text text--darken-2",
    value: "On Hold",
  },
  [CameraStatus.WAITING]: {
    class: "blue-orange--text text--darken-2",
    value: "Waiting for Activation",
  },
  [CameraStatus.UNDER_MAINTENANCE]: {
    class: "yellow--text text--darken-2",
    value: "Under Maintenance",
  },
  [CameraStatus.WAITING_FOR_SITE_VISIT]: {
    class: "yellow--text text--darken-2",
    value: "Under Maintenance (Waiting for Site Visit)",
  },
}

export const CAMERA_STATUSES_LIST = [
  { name: "Decommissioned", value: CameraStatus.DECOMMISSIONED },
  { name: "On Hold", value: CameraStatus.ON_HOLD },
  { name: "Under Maintenance", value: CameraStatus.UNDER_MAINTENANCE },
  {
    name: "Under Maintenance (Waiting for Site Visit)",
    value: CameraStatus.WAITING_FOR_SITE_VISIT,
  },
  { name: "Offline", value: CameraStatus.OFFLINE },
  { name: "Offline (Scheduled)", value: CameraStatus.OFFLINE_SCHEDULED },
  { name: "Waiting for Activation", value: CameraStatus.WAITING },
  { name: "Online", value: CameraStatus.ONLINE },
]

export const CAMERA_STATUS_CHART_CATEGORIES = {
  [CameraStatus.ONLINE]: { class: "online--color--fill" },
  [CameraStatus.OFFLINE]: { class: "offline--color--fill" },
  [CameraStatus.OFFLINE_SCHEDULED]: { class: "offline--color--fill" },
  [CameraStatus.DECOMMISSIONED]: {
    class: "decommissioned--color--fill",
  },
  [CameraStatus.ON_HOLD]: { class: "on_hold--color--fill" },
  [CameraStatus.WAITING]: { class: "waiting--color--fill" },
  [CameraStatus.UNDER_MAINTENANCE]: {
    class: "under_maintenance--color--fill",
  },
  [CameraStatus.WAITING_FOR_SITE_VISIT]: {
    class: "under_maintenance--color--fill",
  },
}

export const CLOUD_RECORDINGS_FREQUENCIES = [
  { value: 60, name: "60 (1 per second)" },
  { value: 30, name: "30 (1 every 2 second)" },
  { value: 12, name: "12 (1 every 5 second)" },
  { value: 6, name: "6 (1 every 10 second)" },
  { value: 4, name: "4 (1 every 15 second)" },
  { value: 2, name: "2 (1 every 30 second)" },
  { value: 1, name: "1 (1 every 60 second)" },
  { value: 5, name: "1 (1 every 5 minutes)" },
  { value: 10, name: "1 (1 every 10 minutes)" },
]

export const CLOUD_RECORDINGS_STORAGE_DURATION = [
  { value: -1, name: "Infinity" },
  { value: 1, name: "24 Hours" },
  { value: 7, name: "7 Days" },
  { value: 30, name: "30 Days" },
  { value: 90, name: "90 Days" },
]

export const CLOUD_RECORDINGS_STATUSES = [
  { name: "On", value: "on", class: "green--text text--darken-2" },
  { name: "Off", value: "off", class: "red--text text--darken-2" },
  { name: "Paused", value: "paused", class: "orange--text text--darken-2" },
  {
    name: "On Scheduled",
    value: "on-scheduled",
    class: "green--text text--darken-2",
  },
]

export const BIM_MODEL_TYPES = Object.entries(BimModelType).reduce(
  (acc, [name, value]) => [...acc, { name, value }],
  []
)

export const BIM_UPLOAD_STATUSES = {
  INITIAL: 0,
  SAVING: 1,
  SUCCESS: 2,
  FAILED: 3,
}

export const DETECTION_MODELS = [
  "yolo_trucks",
  "yolo_counting",
  "yolov5",
  "yolov8_trucks",
]

export const POWER_SCHEDULES = [
  { value: "schedule", name: "Schedule" },
  { value: "24/7", name: "24/7" },
]

export const POWER_TYPES = [
  { value: "mains", name: "Mains" },
  { value: "battery", name: "Battery" },
  { value: "solar", name: "Solar" },
  { value: "generator", name: "Generator" },
]

export const VPN_SERVERS = [
  { value: "None", name: "None" },
  { value: "OpenVPN1", name: "OpenVPN1" },
  { value: "OpenVPN2", name: "OpenVPN2" },
  { value: "OpenVPN3", name: "OpenVPN3" },
  { value: "OpenVPN4", name: "OpenVPN4" },
  { value: "OpenVPN5", name: "OpenVPN5" },
  { value: "PPTP1", name: "PPTP1" },
  { value: "PPTP2", name: "PPTP2" },
  { value: "WireGuard1", name: "WireGuard1" },
  { value: "WireGuard2", name: "WireGuard2" },
  { value: "WireGuard3", name: "WireGuard3" },
  { value: "WireGuard4", name: "WireGuard4" },
  { value: "WireGuard5", name: "WireGuard5" },
  { value: "WireGuard6", name: "WireGuard6" },
  { value: "WireGuard7", name: "WireGuard7" },
  { value: "WireGuard8", name: "WireGuard8" },
  { value: "WireGuard9", name: "WireGuard9" },
  { value: "WireGuard10", name: "WireGuard10" },
]

export const ROUTER_STATUSES = [
  { value: "active", name: "Active" },
  { value: "inactive", name: "Inactive" },
]

export const CAMERA_TABS = {
  SUMMARY: 0,
  EXTRACTIONS: 1,
  RECOGNITION: 2,
  DEMO: 3,
  MILESTONES: 4,
  DANGER_ZONE: 5,
}

export const BrainToolTabs = {
  CHATGPT: 0,
  DETECTIONS: 1,
  SEGMENTATION: 2,
  DEPTH_ANALYSIS: 3,
}

export const CAMERA_SETTINGS = {
  zohoId: "Zoho Id",
  isPowerScheduleActive: "Is Power Schedule Active",
  featureFlags: "Feature Flags",
  recordingFromNvr: "Recording from NVR?",
  "config.externalHost": "Host",
  nvrChannel: "NVR Channel",
  "config.auth.username": "Username",
  "config.auth.password": "Password",
  "config.urls.h264": "h264 URL",
  "config.urls.jpg": "jpg URL",
  "config.urls.nvrJpg": "NVR jpg URL",
  "config.nvrHttpPort": "NVR HTTP port",
  "config.externalRtspPort": "RTSP port",
  "config.externalHttpPort": "HTTP port",
  "config.cameraHttpPort": "Camera HTTP port",
}

export const LAYER_SETTINGS = {
  name: "Name",
  layerType: "Layer type",
  displayName: "Display name",
  cameraId: "Camera",
  "shapes.width": "Shapes width",
  "shapes.rotation": "Shapes rotation",
  "shapes.x": "Shapes x position",
  "shapes.y": "Shapes y position",
  "shapes.scaleX": "Shapes x scale",
  "shapes.scaleY": "Shapes y scale",
  "shapes.height": "Shapes height",
  "shapes.viewerWidth": "Shapes viewer width",
  "shapes.viewerHeight": "Shapes viewer height",
}

export const ROUTER_SETTINGS = {
  serialNumber: "Serial Number",
  routerType: "Router Type",
  routerHttpPort: "Router HTTP Port",
  powerType: "Power Type",
  powerSchedule: "Power Schedule",
  status: "Status",
  vpnUserId: "VPN User ID",
  vpnPassword: "VPN Password",
  routerUserId: "Router User ID",
  routerPassword: "Router Password",
  vpnServer: "VPN Server",
}

export const PROJECT_SETTINGS = {
  exid: "Exid",
  name: "Name",
  status: "Status",
  zohoId: "Zoho ID",
}

export const ROUTER_DEVICE_SETTINGS = {
  id: "ID",
  mac: "MAC",
  imei: "IMEI",
  imsi: "IMSI",
  name: "Name",
  model: "Model",
  serial: "serial",
  status: "Status",
  wanIp: "WAN IP",
  cellId: "Cell ID",
  firmware: "Firmware",
  wanState: "WAN State",
  modemModel: "Modem Model",
  companyName: "Company Name",
  productCode: "Product Code",
  modemFirmware: "Modem Firmware",
  originalModel: "Original Model",
  connectionType: "Connection Type",
  bootloaderVersion: "Bootloader Version",
  creditExpireDate: "Credit Expire Date",
}

export const NVR_SETTINGS = {
  model: "Model",
  deviceName: "Device",
  macAddress: "Mac Address",
  firmwareVersion: "Firmware",
  resolution: "Resolution",
  bitrateType: "Bitrate Type",
  videoQuality: "Video Quality",
  frameRate: "FPS",
  bitrate: "Max Bitrate",
  videoEncoding: "Encoding",
  hddName: "HDD Name",
  hddCapacity: "HDD Capacity",
  freeSpace: "HDD Free Space",
  hddStatus: "HDD Status",
  hddProperty: "HDD Property",
  nvrStatus: "NVR Status",
  timeMode: "Time Mode",
  localTime: "Local Time",
  timezone: "Timezone",
  addressingFormatType: "NTP Addressing Format Type",
  hostName: "NTP Host Name",
  portNo: "NTP Port No",
  synchronizeInterval: "NTP Synchronize Interval",
}

export const ROI_SETTINGS = {
  name: "Name",
  shapes: "Shapes",
}

export const HISTORY_DAYS = [
  { text: "Today", value: "1" },
  { text: "7 Days", value: "7" },
  { text: "15 Days", value: "15" },
  { text: "30 Days", value: "30" },
]

export const CLOUD_RECORDINGS_SETTINGS = [
  { name: "Status", attr: "status" },
  { name: "Storage Duration", attr: "storageDuration" },
  { name: "Frequency", attr: "frequency" },
  { name: "Schedule", attr: "schedule" },
]
export const CAUSE_DESCRIPTIONS = {
  caseClause: "( Cause: Bad request.)",
  badRequest: "( Cause: Bad request )",
  closed: "( Cause: Connection closed. )",
  nxdomain: "( Cause: Non-existant domain. )",
  ehostunreach: "( Cause: No route to host. )",
  enetunreach: "( Cause: Network unreachable. )",
  reqTimedout: "( Cause: Request to the camera timed out. )",
  timeout: "( Cause: Camera response timed out. )",
  connectTimeout: "( Cause: Connection to the camera timed out. )",
  econnrefused: "( Cause: Connection refused. )",
  notFound: "( Cause: Camera url is not found. )",
  forbidden: "( Cause: Camera responded with a Forbidden message. )",
  unauthorized: "( Cause: Please check the username and password. )",
  deviceError: "( Cause: Camera responded with a Device Error message. )",
  deviceBusy: "( Cause: Camera responded with a Device Busy message. )",
  moved: "( Cause: Camera url has changed, please update it. )",
  notAJpeg: "( Cause: Camera didn't respond with an image. )",
  unhandled: "( Cause: Sorry, we dropped the ball. )",
}

export const PERSONA_ITEMS = [
  { value: Persona.HQ, name: "Project Overview (e.g. Investor / Head Office)" },
  { value: Persona.PM, name: "Project Management (On Site)" },
  {
    value: Persona.QS,
    name: "Dispute Avoidance & Contract Administration (e.g. QS)",
  },
  { value: Persona.Marketing, name: "Marketing" },
  {
    value: Persona.HS,
    name: "Incident Review & Continuous Improvement (e.g. Health & Safety)",
  },
  {
    value: Persona.DI,
    name: "Digital / Innovation",
  },
  { value: Persona.Other, name: "Other" },
  { value: "None", name: "None: Not Construction Related" },
]

export const SNAPSHOT_EXTRACTION_INTERVALS = [
  {
    text: "All",
    value: 1,
  },
  {
    text: "1 Frame Every 5 seconds",
    value: 5,
  },
  {
    text: "1 Frame Every 10 seconds",
    value: 10,
  },
  {
    text: "1 Frame Every 15 seconds",
    value: 15,
  },
  {
    text: "1 Frame Every 20 seconds",
    value: 20,
  },
  {
    text: "1 Frame Every 30 seconds",
    value: 30,
  },
  {
    text: "1 Frame Every 1 min",
    value: 60,
  },
  {
    text: "1 Frame Every 5 min",
    value: 300,
  },
  {
    text: "1 Frame Every 10 min",
    value: 600,
  },
  {
    text: "1 Frame Every 15 min",
    value: 900,
  },
  {
    text: "1 Frame Every 20 min",
    value: 1200,
  },
  {
    text: "1 Frame Every 30 min",
    value: 1800,
  },
  {
    text: "1 Frame Every hour",
    value: 3600,
  },
  {
    text: "1 Frame Every 2 hours",
    value: 7200,
  },
  {
    text: "1 Frame Every 6 hours",
    value: 21600,
  },
  {
    text: "1 Frame Every 12 hours",
    value: 43200,
  },
  {
    text: "1 Frame Every 24 hours",
    value: 86400,
  },
]

export const PROJECT_STATUSES = Object.values(ProjectStatus)

export const PROJECT_STATUSES_IDS_CLASSES = {
  [ProjectStatus.InProgress]: {
    class: "green--text text--darken-2",
    value: "In Progress",
  },
  [ProjectStatus.notAplicable]: {
    class: "red--text text--darken-2",
    value: "Not Applicable",
  },
  [ProjectStatus.Completed]: {
    class: "cyan--text text--darken-2",
    value: "Completed",
  },
  [ProjectStatus.ToStart]: {
    class: "yellow--text text--darken-2",
    value: "To Start",
  },
}

export const USER_ACCESS_FEATURES = [
  { value: UserFeatureFlag.CompanyAdmin, name: "Company Admin" },
  { value: UserFeatureFlag.VideoWalls, name: "Video Wall" },
  {
    value: UserFeatureFlag.MassCameraSharing,
    name: "Project sharing + share camera with all project members",
  },
  { value: UserFeatureFlag.Copilot, name: "Copilot" },
]

export const PROJECT_FEATURE_FLAGS = [
  { value: ProjectFeatureFlag._360View, name: "360 View" },
  {
    value: ProjectFeatureFlag.BIM,
    name: "BIM",
    children: [
      {
        value: ProjectFeatureFlag.BIM_ITWIN,
        name: "Bentley iTwin",
      },
      { value: ProjectFeatureFlag.BIM_FORGE, name: "Autodesk Forge" },
    ],
  },
  { value: ProjectFeatureFlag.DRONE_VIEW, name: "Drone View" },
  {
    value: ProjectFeatureFlag.GATE_REPORT,
    name: "Gate Report",
    children: [
      {
        value: ProjectFeatureFlag.GATE_REPORT_AUTO_VERIFY,
        name: "Auto Publish Events",
      },
      {
        value: ProjectFeatureFlag.GATE_REPORT_MOTION_DETECTION,
        name: "Motion detection",
      },
      {
        value: ProjectFeatureFlag.GATE_REPORT_ANPR_BASED,
        name: "ANPR sourced events",
      },
    ],
  },
  { value: ProjectFeatureFlag.NDA_MC, name: "NDA/MC" },
  { value: ProjectFeatureFlag.MediaHubSharing, name: "Media Hub Sharing" },
  { value: ProjectFeatureFlag.TIMELINE, name: "Timeline" },
  {
    value: ProjectFeatureFlag.SMART_MILESTONES,
    name: "Smart-milestones",
  },
]

export const CAMERA_FEATURE_FLAGS = [
  {
    name: "Edge video",
    value: CameraFeatureFlag.EdgeVideo,
  },
  {
    name: "Gate Report",
    value: CameraFeatureFlag.GateReport,
    children: [
      {
        name: "Full / Empty",
        value: CameraFeatureFlag.FullEmptyFiltering,
      },
    ],
  },
  {
    name: "Bim Compare",
    value: CameraFeatureFlag.BimCompare,
  },
  {
    name: "Stream HLS",
    value: CameraFeatureFlag.VideoStream,
  },
  {
    name: "Stream WebRTC",
    value: CameraFeatureFlag.WebRTC,
  },
  {
    name: "ANPR",
    value: CameraFeatureFlag.ANPR,
    children: [
      {
        name: "Reverse ANPR",
        value: CameraFeatureFlag.ReverseANPR,
      },
    ],
  },
  {
    name: "PTZ",
    value: CameraFeatureFlag.PTZ,
  },
  {
    name: "Depth Estimation",
    value: CameraFeatureFlag.DepthEstimation,
  },
  {
    name: "Copilot Timelapse Reports",
    value: CameraFeatureFlag.CopilotTimelapseReport,
  },
  {
    name: "Object Detection",
    value: CameraFeatureFlag.ObjectDetection,
  },
]

export const SCHEDULE_TYPES = {
  CONTINUOUS: "continuous",
  ON_SCHEDULE: "on_schedule",
  WORKING_HOURS: "working_hours",
}

export const CHART_BORDER_COLORS = {
  red: "rgba(255, 99, 132, 1)",
  blue: "rgba(99, 217, 255, 1)",
  green: "rgba(75, 192, 192, 1)",
  purple: "rgba(168, 98, 255, 1)",
  yellow: "rgba(255, 241, 161, 1)",
}

export const CHART_BACKGROUND_COLORS = {
  red: "rgba(255, 99, 132, 0.2)",
  blue: "rgba(99, 217, 255, 0.2)",
  green: "rgba(75, 192, 192, 0.2)",
  purple: "rgba(168, 98, 255, 0.2)",
  yellow: "rgba(255, 241, 161, 0.2)",
}

export const ROI_TYPES = [
  {
    type: "Gate",
    value: "gate",
  },
  {
    type: "Anpr Context",
    value: "anpr_context",
  },
  {
    type: "Exclusion Zone",
    value: "exclusion_zone",
  },
  {
    type: "Workflow Area",
    value: "workflow_area",
  },
]

export const DIRECTION_TYPES = [
  {
    value: "arrived",
    type: "Arrived",
  },
  {
    value: "left",
    type: "Left",
  },
  {
    value: "arrived,left",
    type: "Both",
  },
]
export const _360_INTEGRATION_TYPE = [
  { name: "Matterport", value: "Matterport" },
  { name: "Openspace", value: "Openspace" },
  { name: "Holobuilder", value: "Holobuilder" },
  { name: "Dronedeploy", value: "Dronedeploy" },
  { name: "Evercam", value: "Evercam" },
]

export const MAP_STYLES = [
  { name: "map", value: "terrain" },
  { name: "satellite", value: "hybrid" },
]

export const YES_NO_SELECT = [
  { name: "Yes", value: "Yes" },
  { name: "No", value: "No" },
]

export const TRUE_FALSE_SELECT = [
  { name: "Yes", value: true },
  { name: "No", value: false },
]

export const INGEST_PROCESSING_STATUSES = {
  completed: "completed",
  uploading: "uploading",
  created: "created",
  failed: "failed",
  aborted: "aborted",
  paused: "paused",
  inprogress: "inprogress",
  pending: "pending",
}

export const PROCESSING_STATUS_OPTIONS = [
  { name: "Created", value: INGEST_PROCESSING_STATUSES.created },
  { name: "Pending", value: INGEST_PROCESSING_STATUSES.pending },
  { name: "In Progress", value: INGEST_PROCESSING_STATUSES.inprogress },
  { name: "Completed", value: INGEST_PROCESSING_STATUSES.completed },
  { name: "Failed", value: INGEST_PROCESSING_STATUSES.failed },
  { name: "Aborted", value: INGEST_PROCESSING_STATUSES.aborted },
  { name: "Paused", value: INGEST_PROCESSING_STATUSES.paused },
  { name: "Uploading", value: INGEST_PROCESSING_STATUSES.uploading },
]

export const REGIONS = [
  { name: "APAC", value: "apac" },
  { name: "AU", value: "au" },
  { name: "SG", value: "sg" },
  { name: "EU", value: "eu" },
  { name: "UK", value: "uk" },
  { name: "IE", value: "ie" },
  { name: "US", value: "us" },
]

export const INGEST_UPLOADS_TYPES = {
  droneUploads: "drone uploads",
  _360Assets: "360 Assets",
  bimUploads: "BIM uploads",
}

export const ASSETS_360_TYPES = {
  video: "video",
  image: "image",
  model: "model",
  route: "route",
  mobilePhoto: "mobile_photos",
  mobileAudio: "mobile_audios",
}

export const RESOLUTIONS = Object.entries(ResolutionDimensions).reduce(
  (acc, [key, value]) => {
    if (key in ResolutionLabel) {
      acc[value] = ResolutionLabel[key]
    }

    return acc
  },
  {}
)

export default {}
